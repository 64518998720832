import type { Toast, CustomToast, IToastOptions } from 'o365.modules.ToastService.ts';
import { default as ToastService,  ToastType} from 'o365.modules.ToastService.ts';
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
import { markRaw } from 'vue';
const ToastWithPromise = useAsyncComponent('o365.vue.components.ToastService.ToastWithPromise.vue');
type ToastTypeString = 'danger' | 'info' | 'success' | 'warning' | 'primary';

/**
 * Show an alert with default toast component
 */
export default function alert(pText: string, pType: ToastType | ToastTypeString = ToastType.Danger, pOptions: IAlertOptions = {}, pToastOptions?: IToastOptions) {
    const toastService = ToastService.getToastService();

    if (typeof pText !== 'string') {
        if (pText['error']) {
            pText = pText['error'];
        } else if ((pText as any) instanceof Response) {
            // alert recieved unparsed response object
            // if you hit this debugger please check where this alert comes from
            // and make sure the parsed response is given here
            debugger;
            pText = 'Network Error';
        } else {
            try {
                pText = pText.toString();
            } catch (ex) {
                console.warn('Failed to show Toast message, reason:\n', ex);
                return;
            }
        }
    }

    const getIconFromType = (type: ToastType) => {
        switch (type) {
            case ToastType.Info:
                return 'bi bi-info-circle-fill';
            case ToastType.Success:
                return 'bi bi-check-lg';
            default:
                return 'bi bi-exclamation-triangle-fill';
        }
    };

    const toastOptions: Toast = {
        message: pText,
        iconClass: getIconFromType(pType),
        type: pType,
        category: pType,
        toastOptions: {
            autohide: pOptions.autohide ?? false,
            delay: pOptions.delay ?? 5000,
            slimVersion: pOptions.slimVersion ?? false
        }
    };

    const toastUID = toastService.addToast(toastOptions, pToastOptions);

    return {
        updateMessage: (newMessage: string) => {
            const state = toastService.state.find(toastUID);
            if (!state) { return; }
            (<Toast>state.value).message = newMessage;
        },
        updateType: (newType: ToastType) => {
            const state = toastService.state.find(toastUID);
            if (!state) { return; }
            (<Toast>state.value).type = newType;
            (<Toast>state.value).category = newType;
            (<Toast>state.value).iconClass = getIconFromType(newType); 
        },
        close: () => {
            const state = toastService.state.find(toastUID);
            if (!state) { return; }
            toastService.state.remove(toastUID);
        },
    };
}

/**
 * Show a custom alert content component
 */
export function customAlert<C, T>(pComponent: C, pProps: T, pOptions: IAlertOptions = {}, pTOptions?: IToastOptions) {
    const toastService = ToastService.getToastService();
    const component: C = markRaw(pComponent);

    const customToastOptions = {
        component: component,
        props: pProps,
        toastClass: pOptions.toastClass,
        toastOptions: {
            autohide: pOptions.autohide ?? false,
            delay: pOptions.delay ?? 5000
        }
    };
    
    const toastUID = toastService.addCustomToast(customToastOptions, pTOptions);

    return {
        updateMessage: (newMessage: string) => {
            const state = toastService.state.find(toastUID);
            if (!state) { return; }
            (<CustomToast>state.value).props.message = newMessage;
        },
        close: () => {
            const state = toastService.state.find(toastUID);
            if (!state) { return; }
            toastService.state.remove(toastUID);
        },
    }
}

/**
 * Alert function that will show a spinenr while the promise is unresolved.
 * @param pMessage message shown in the toast
 * @param pPromise promise that will be used for await
 * @param pType toast alert type
 * @param pAutoClose close the alert after the promise is resovled, reject will keep it open
 */
export function promiseAlert<T>(pMessage: string, pPromise: Promise<T>, pType: ToastTypeString = 'info', pAutoClose?: boolean) {
    const customToastInstance = customAlert(ToastWithPromise, {
        message: pMessage,
        promise: pPromise,
        type: pType
    }, undefined, {
        noProgressBar: true,
    })
    if (pAutoClose) {
        pPromise.then(() => window.setTimeout(() => customToastInstance.close(), 500));
    }
    return customToastInstance;
}


interface IAlertOptions {
    autohide?: boolean,
    delay?: number,
    toastClass?: any,
    slimVersion?: boolean
};

export { alert };